<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('国外')" />
		
		<div class="container">
			<div class="row">
				<div class="left-home col-lg-9 col-md-9 col-sm-9 col-xs-12">
					<div class="pageone">
						<div class="pageone-title" style="margin-bottom: -10PX;">
							<h2><a style='float:left;font-size: 15PX;margin-bottom: -10px;' href="javascript:void(0);" title="">VIỆC LÀM NHẬT BẢN MỚI NHẤT</a></h2>
							<p class="line-do"></p>
						</div>
						<div class="pageone-tab">
							<ul class="nav nav-tabs" role="tablist">
								<li class="active" style="background: #f58220;height: 32px;"><a href="javascript:void(0);" role="tab" data-toggle="tab">Tất cả đơn hàng mới</a></li>
								<li><a href="javascript:void(0);" style='height: 32px;' role="tab" data-toggle="tab">Kỹ sư đi Nhật Bản</a></li>
								<li><a href="javascript:void(0);" style='height: 32px;' role="tab" data-toggle="tab">Đơn hàng cho nam</a></li>
								<li><a href="javascript:void(0);" style='height: 32px;' role="tab" data-toggle="tab">Đơn hàng cho nữ</a></li>
							</ul>
							<div class="tab-content" id="style-2">
								<div class="tab-pane active" id="thong-so-ki-thuat">
									<table class="donhang-home">
										<tbody>
											<tr>
												<td>Ngành nghề</td>
												<td>Ngày thi</td>
												<td>Giới tính</td>
												<td>Lương cơ bản</td>
												<td>Số lượng</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="[Phí tổng 105tr] Đơn hàng chế biến thực phẩm cho nữ đi Nhật Bản">Finance</a></td>
												<td>15/09/2023</td>
												<td>Nữ </td>
												<td>3564.054 AUD </td>
												<td>30</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng Tokutei thực phẩm đầu Việt làm sủi cảo tỉnh Gunma">Estate</a></td>
												<td>25/09/2023</td>
												<td>Nam/Nữ </td>
												<td>5253.000 AUD </td>
												<td>24</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD">Công xưởng</a></td>
												<td>28/09/2023</td>
												<td>Nam Nữ </td>
												<td>4000 AUD </td>
												<td>100</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng chế biến thực phẩm tại Nhật Bản tuyển 18 nữ">Doctor</a></td>
												<td>18/09/2023</td>
												<td>Nữ </td>
												<td>80000.000 CNY </td>
												<td>18</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Tuyển 30 nữ làm đóng gói công nghiệp tại Nhật Bản làm ở tỉnh Tokyo">Science and technology</a></td>
												<td>16/09/2023</td>
												<td>Nữ </td>
												<td>157.000 Yên </td>
												<td>30</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng truyền thống tuyển 100 nữ và 20 nam làm chế biến thủy sản Nhật Bản">Hotel manager</a></td>
												<td>25/09/2023</td>
												<td>Nam Nữ </td>
												<td>5231.000 AUD </td>
												<td>120</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng dập kim loại linh kiện ô tô Nissan Nhật Bản tuyển 54 nam">Cơ khí</a></td>
												<td>24/09/2023</td>
												<td>Nam </td>
												<td>160.768 Yên </td>
												<td>54</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng chế biến đồ ăn sẵn tại Nhật Bản tuyển 60 nữ làm ở siêu thị">Thực phẩm</a></td>
												<td>15/09/2023</td>
												<td>Nữ </td>
												<td>157.000 Yên </td>
												<td>60</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng chế biến thực phẩm tại Kyoto Nhật Bản tuyển 54 nữ">Thực phẩm</a></td>
												<td>24/09/2023</td>
												<td>Nữ </td>
												<td>157.000 Yên </td>
												<td>54</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Đơn hàng công xưởng Nhật Bản tuyển 20 nam tại tỉnh Osaka">Phân Loại</a></td>
												<td>29/09/2023</td>
												<td>Nam </td>
												<td>167.385 Yên </td>
												<td>20</td>
												
											</tr>
										</tbody>
									</table>
									<div class="xemthemhome"><a href="javascript:void(0);" title="Xem thêm">Xem thêm</a></div>
								</div>
								
								
							
							</div>
						</div>
					</div>
					<!-- Tin việc làm nước ngoài mới nhất -->
					<div class="widget widget-highlight-job pagetow">
						<h2 class="widget-title"><a href="javascript:void(0);" title="Việc làm ngoài nước mới nhất">Việc làm ngoài nước mới nhất <font style="float:right; color:red; text-transform:none; font-size:13px; margin-right:10px;    text-decoration: underline;">Xem thêm</font></a> </h2>
						<div class="clearfix"></div>
						<div class="widget-wrap scrollbar">
							<div class="widget-content">
								<ul>
									<li>
										<a href="javascript:void(0);" title="Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD">
											<h3>Tuyển sinh du học nghề Úc vừa học vừa làm cơ hội kiếm 4000 UAD</h3>
											<h4><span>Mức lương:</span>4000 AUD </h4>
											<h4><span>Quốc gia:</span>Úc</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="41 đơn hàng Singapore mới nhất tuyển liên tục">
											<h3>41 đơn hàng Singapore mới nhất tuyển liên tục</h3>
											<h4><span>Mức lương:</span>40000 </h4>
											<h4><span>Quốc gia:</span>Singapore</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts">
											<h3>Tuyển sinh du học Úc không cần chứng chỉ tiếng anh Ielts</h3>
											<h4><span>Mức lương:</span>25 AUD/giờ </h4>
											<h4><span>Quốc gia:</span>Úc</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói">
											<h3>Xuất khẩu lao động Ba Lan tuyển Nam Nữ đóng gói</h3>
											<h4><span>Mức lương:</span>1.100 USD </h4>
											<h4><span>Quốc gia:</span>Ba lan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm">
											<h3>Xuất khẩu lao động Ba Lan tuyển nam nữ làm thực phẩm</h3>
											<h4><span>Mức lương:</span>1.100 USD </h4>
											<h4><span>Quốc gia:</span>Ba lan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Thông báo chương trình xuất khẩu lao động Đức được nợ phí">
											<h3>Thông báo chương trình xuất khẩu lao động Đức được nợ phí</h3>
											<h4><span>Mức lương:</span>80 triệu </h4>
											<h4><span>Quốc gia:</span>CHLB Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu">
											<h3>Visa E7 Hàn Quốc tuyển thợ cơ khí thợ điện làm việc tại nhà máy đóng tàu</h3>
											<h4><span>Mức lương:</span>2,7 triệu Won </h4>
											<h4><span>Quốc gia:</span>Hàn Quốc</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông">
											<h3>Đơn hàng xây dựng tại Singapore tuyển 1300 lao động phổ thông</h3>
											<h4><span>Mức lương:</span>3000 SGD </h4>
											<h4><span>Quốc gia:</span>Singapore</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Thông báo tuyển lao động đi làm việc tại CHLB Đức">
											<h3>Thông báo tuyển lao động đi làm việc tại CHLB Đức</h3>
											<h4><span>Mức lương:</span>2400 Eur </h4>
											<h4><span>Quốc gia:</span>Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng">
											<h3>Xuất khẩu lao động Philippines tuyển nhân viên Sale Marketing phí O đồng</h3>
											<h4><span>Mức lương:</span>36 Triệu đồng </h4>
											<h4><span>Quốc gia:</span>Philippines</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp">
											<h3>Xuất khẩu lao động Australia Úc Tuyển 1500 nam nữ làm nông nghiệp</h3>
											<h4><span>Mức lương:</span>85 triệu </h4>
											<h4><span>Quốc gia:</span>Australia Úc</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm">
											<h3>Tuyển đầu bếp làm việc tại Đức không yêu cầu tiếng chỉ yêu cầu bảo hiểm</h3>
											<h4><span>Mức lương:</span>2.800 Euro </h4>
											<h4><span>Quốc gia:</span>CHLB Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng">
											<h3>Xuất khẩu lao động New Zealand đơn hàng tuyển nam nữ phục vụ nhà hàng</h3>
											<h4><span>Mức lương:</span>80 triệu </h4>
											<h4><span>Quốc gia:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển lao động làm xây dựng tại New Zealand">
											<h3>Tuyển lao động làm xây dựng tại New Zealand</h3>
											<h4><span>Mức lương:</span>4000- 6,500 NZD </h4>
											<h4><span>Quốc gia:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand">
											<h3>Ngành nông nghiệp tuyển lao động nam nữ làm farm ở New Zealand</h3>
											<h4><span>Mức lương:</span>5000-5500 NZ </h4>
											<h4><span>Quốc gia:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao">
											<h3>Tuyển thợ hàn đi Hungary và Slovakia Châu Âu phí rẻ lương cao</h3>
											<h4><span>Mức lương:</span>2.200 Euro </h4>
											<h4><span>Quốc gia:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới">
											<h3>Xuất khẩu lao động Hungary với 5 đơn hàng tuyển mới</h3>
											<h4><span>Mức lương:</span>1.100 USD </h4>
											<h4><span>Quốc gia:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử">
											<h3>3 đơn hàng xuất khẩu lao động Hungary làm Phiên dịch, đốc công, điện tử</h3>
											<h4><span>Mức lương:</span>2000USD </h4>
											<h4><span>Quốc gia:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung">
											<h3>Xuất khẩu lao động Đài Loan tuyển 15 nam nữ làm sản xuất máy chạy bộ tại Đài Trung</h3>
											<h4><span>Mức lương:</span>23.800 Đài tệ </h4>
											<h4><span>Quốc gia:</span>Đài Loan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani">
											<h3>Tuyển đơn hàng phụ nhà hàng và đầu bếp tại Rumani</h3>
											<h4><span>Mức lương:</span>1000 USD </h4>
											<h4><span>Quốc gia:</span>Rumani</h4> </a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="clear"></div>
					<div class="xemthemhome" style='margin-top: 80PX;'><a href="javascript:void(0);" title="Xem thêm"></a></div>
					<!-- ./. Tin việc làm nước ngoài mới nhất -->
					
					
				</div>
				
			</div>
		</div>
		
		<div class="call-mobile" style="">
			<a class="" href="tel:0348148888"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0348.14.8888</a>
		</div>
		
		<div class="call-mobile" style="display:none;">
			<a class="" href="tel:0327089999"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0327.08.9999</a>
		</div>
		
		<!--div class="call-mobile" style="bottom: 117px;">
			<a class="" href="tel:0587236666"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0587236666</a>
		</div-->
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style src='../../assets/css/ttc.css' scoped></style>
<!-- <style src='../../assets/css/responsive.css' scoped></style> -->
<!-- <style src='../../assets/css/bootstrap.min.css' scoped></style> -->

<style lang="less" scoped>
	// @import "../../assets/css/ttc.css";
	// @import "../../assets/css/responsive.css";
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg,#500cfd,#9e9ce7);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #500cfd;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
}
.van-cell {
    padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw;
}
.pageone-title h2 {
margin: 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
	}
.pageone-title h2 {
    font-size: 15PX;
    display: block;
	text-transform: uppercase;
	    font-weight: bold;
}
// .container {
//     font-family: Inter,sans-serif;
//     font-weight: 400;
//     color: #fff!important;
// }
.container {
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    // font-size: 14PX;
    // line-height: 1.42857143;
    // color: #333;
    background-color: #fff;
	z-index: 1!important;
}
.pageone-tab .nav-tabs>li {
    width: 50%!important;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    float: left;
}
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-tabs {
    border-bottom: 1px solid #ddd;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
.tab-content {
    display: block;
    // overflow-x: scroll;
}
</style>
